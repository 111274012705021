export const events = [
    {
        id: 1,
        title: "BACHELOR OF PHARMACY (B.PHARMA)",
        courseDuration: "4 Years",
        eligibility: "12th with PCM/B (minimum of 50% aggregate marks)",
        img: "/photos/snbImages/c3.png",
        description:
            "The B Pharma course, also known as Bachelor of Pharmacy, is a 4-year UG degree program in the field of Pharmacy education. B Pharma course is designed to provide students with comprehensive knowledge and skills related to pharmaceutical sciences. B Pharma course curriculum includes subjects such as pharmacology, medicinal chemistry, pharmaceutics, pharmacognosy, and pharmacy practice. In the B Pharma course, students learn about drug formulation, therapeutic drug monitoring, and the regulatory aspects of the pharmaceutical industry",
        careerOpportunities: [
            "Industry: as Industry Pharmacist/ Chemist/Executive",
            "Sales and Marketing executive",
            "Drug Inspector",
            "Hospital and community pharmacist",
            "Entrepreneur",
            "Health Insurance executive",
            "Knowledge processing organizations",
            "Clinical research coordinators",
            "Medical Writing",
            "Pharmacovigilance executive",
        ],
        courseObjectives: [
            "Understanding Pharmaceutical Sciences",
            "Knowledge of Drug Action and Usage",
            "Drug Formulation and Development",
            "Clinical and Therapeutic Knowledge",
            "Regulatory and Ethical Understanding",
            "Research and Innovation",
            "Communication and Interpersonal Skills",
            "Professional Development",
            "Community and Public Health Awareness",
            "Preparation for Further Studies or Specialization",
        ],
    },
    {
        id: 2,
        title: "BACHELOR OF BUSINESS ADMINISTRATION (B.B.A)",
        courseDuration: "3 Years",
        eligibility: "12th with (minimum of 50% aggregate marks)",
        img: "/photos/snbImages/c7.png",
        description:
            "BBA full form is Bachelor of Business Administration. BBA Course is a 3-year professional-level undergraduate program pursued by individuals willing to build careers in business and management. BBA Curriculum is broad, focusing on different foundational business courses including subjects like Principles of Management, Economics, Marketing, Finance, and Human Resource Management. BBA Courses are a perfect blend of theoretical learning and practical experiences. For theoretical learning, classroom lectures are held, and to provide practical exposure various training programs, workshops, and guest lectures are being organized.",
        careerOpportunities: [
            "Accountant",
            "Financial Advisor",
            "Marketer",
            "Commodities Trader",
            "Human Resources",
            "Loan Officer",
            "Real Estate Agent",
            "Manager",
            "Finance Sector: Financial Advisor, Accountant, Trader, Investment Banker, Loan Officer, etc.",
            "Management Sector: Human Resource Manager, Information and Systems Manager, Research and Development Manager",
            "Marketing",
            "Real Estate Business",
            "Sales Executive",
            "Advertising",
            "Aviation",
        ],
        courseObjectives: [
            "Foundation in Business Knowledge: To impart fundamental knowledge of various disciplines within business administration such as accounting, finance, marketing, operations, human resources, and management information systems.",
            "Critical Thinking and Analytical Skills: To develop analytical and problem-solving skills necessary for evaluating business situations, making informed decisions, and implementing effective solutions.",
            "Communication Skills: To enhance communication abilities, both oral and written, necessary for effective business communication, presentations, negotiations, and interpersonal interactions in professional settings.",
            "Leadership and Teamwork: To cultivate leadership qualities and teamwork skills essential for collaborating with others, managing teams, and leading organizations towards achieving strategic goals.",
            "Ethical and Social Responsibility: To instill an understanding of ethical principles, corporate social responsibility, and business ethics, ensuring students develop a sense of accountability towards stakeholders and society.",
            "Global Perspective: To provide insights into global business environments, international markets, cultural diversity, and the implications of globalization on business operations and strategies.",
            "Entrepreneurial Mindset: To foster an entrepreneurial mindset and innovative thinking, encouraging students to identify opportunities, take calculated risks, and develop entrepreneurial ventures or initiatives within established organizations.",
            "Technological Proficiency: To familiarize students with the use of technology and information systems in business processes, enhancing their ability to leverage technology for strategic advantage and operational efficiency.",
            "Professional Development: To prepare students for professional careers by equipping them with resume writing skills, interview techniques, networking strategies, and other career development skills essential for securing internships and job placements.",
            "Preparation for Advanced Studies: To provide a solid foundation for further education, such as pursuing a Master of Business Administration (MBA) or other specialized graduate programs in areas like finance, marketing, entrepreneurship, or international business.",
        ],
    },
    {
        id: 3,
        title: "BACHELOR IN COMPUTER APPLICATION (B.C.A)",
        courseDuration: "3 Years",
        eligibility: "12th with (minimum of 50% aggregate marks)",
        img: "/photos/snbImages/c1.png",
        description:
            "The BCA full form is Bachelor of Computer Applications (BCA). It is a three-year undergraduate-level program that primarily focuses on computer applications and software development. It serves as a gateway for students who wish to pursue a career in the IT (Information and Technology) field. Moreover, the IT industry is one of the fastest-growing industries in India, and there is a high demand for skilled IT professionals. BCA course equips students with various IT skills, fostering proficiency in programming languages like C, C++, Java, Python, Database Management Systems (DBMS), cyber security, operating systems (Windows, Linux), computer networks and software development methodologies, and web development technologies such as HTML, CSS, and JavaScript.",
        careerOpportunities: [
            "Cybersecurity Analyst",
            "IT Support Specialist",
            "Software Tester/QA Analyst",
            "Business Analyst",
            "IT Consultant",
            "Web Designer/Developer",
            "Network Administrator",
            "Database Administrator",
            "System Analyst",
            "Software Developer/Programmer",
        ],
        courseObjectives: [
            "Foundation in Computer Science: To provide a solid foundation in core areas of computer science such as programming languages, data structures, algorithms, computer architecture, and operating systems.",
            "Software Development Skills: To develop programming skills in languages such as C, C++, Java, Python, or others, enabling students to design, develop, and implement software applications.",
            "Database Management: To impart knowledge of database management systems (DBMS), SQL, and data handling techniques, preparing students to design and manage databases efficiently.",
            "Web Technologies: To familiarize students with web development technologies including HTML, CSS, JavaScript, and server-side scripting languages like PHP, ASP.NET, or Node.js, allowing them to develop dynamic and interactive web applications.",
            "Networking and Security: To introduce concepts of computer networks, network protocols, and security measures, equipping students with skills to manage network infrastructure and ensure data security.",
            "System Analysis and Design: To teach methodologies for analyzing business requirements, designing information systems, and evaluating system performance to meet organizational needs.",
            "Software Engineering Practices: To instill principles of software engineering such as software testing, debugging, version control, and software lifecycle management, ensuring students can develop robust and maintainable software solutions.",
            "Emerging Technologies: To expose students to emerging trends and technologies in IT such as cloud computing, big data analytics, artificial intelligence, machine learning, and IoT (Internet of Things).",
            "Soft Skills and Professional Development: To develop communication skills, teamwork abilities, problem-solving capabilities, and a professional work ethic essential for success in the IT industry.",
            "Project Work and Practical Experience: To provide hands-on experience through project work, internships, or industry collaborations, allowing students to apply theoretical knowledge in practical scenarios and gain real-world exposure.",
            "Ethical and Social Responsibilities: To promote awareness of ethical issues related to technology use, privacy concerns, intellectual property rights, and the societal impact of IT solutions.",
            "Preparation for Further Studies or Certifications: To prepare students for advanced studies in computer science, IT management, or specialized certifications in areas such as cybersecurity, cloud computing, data science, or software development.",
        ],
    },
    {
        id: 4,
        title: "DIPLOMA IN PHARMACY (D. PHARM)",
        courseDuration: "2 Years",
        eligibility: "12th with PCM/B (minimum of 50% aggregate marks)",
        img: "/photos/snbImages/c4.png",
        description:
            "D Pharma full form is Diploma in Pharmacy. D Pharma course is a 2-year diploma-level course in the field of Pharmacy that trains students in various aspects of pharmaceutical science, including pharmacy practice, pharmacology, pharmaceutical chemistry, and pharmacognosy. D Pharma course aims to equip students with the knowledge and skills required to work as pharmacy technicians or assistants in pharmacies, hospitals, pharmaceutical companies, and other healthcare settings.",
        careerOpportunities: [
            "Pharmacist",
            "Hospital Pharmacy",
            "Clinical Research Associate (CRA)",
            "Medical Representative",
            "Pharmaceutical Sales",
            "Production and Manufacturing",
            "Quality Control and Assurance",
            "Regulatory Affairs",
            "Community Pharmacy Ownership",
            "Academic and Research Institutions",
        ],
        courseObjectives: [
            "Fundamental Knowledge of Pharmacy: To provide students with a solid foundation in pharmaceutical sciences including pharmacology, pharmaceutics, pharmaceutical chemistry, pharmacognosy, and hospital pharmacy.",
            "Understanding of Drug Formulation and Development: To impart knowledge on the formulation, preparation, and dispensing of pharmaceutical dosage forms such as tablets, capsules, ointments, syrups, and injections.",
            "Pharmacy Practice and Ethics: To familiarize students with the principles of pharmacy practice, ethical considerations, legal aspects, and responsibilities of pharmacists towards patients, healthcare providers, and regulatory bodies.",
            "Drug Dispensing and Patient Counseling: To develop skills in drug dispensing techniques, prescription reading, medication labeling, and patient counseling on medication use, dosage, administration, and possible side effects.",
            "Pharmaceutical Chemistry: To provide understanding of pharmaceutical chemistry, including principles of organic chemistry, inorganic chemistry, and medicinal chemistry related to drug design, structure-activity relationships, and drug metabolism.",
            "Pharmacology: To educate students about the action, therapeutic uses, adverse effects, and interactions of drugs on the human body systems, including pharmacokinetics and pharmacodynamics.",
            "Pharmaceutical Microbiology and Biotechnology: To introduce students to pharmaceutical microbiology, biotechnology applications in pharmacy, and principles of sterile product preparation and quality control.",
            "Quality Assurance and Regulatory Compliance: To familiarize students with quality assurance practices, Good Manufacturing Practices (GMP), Good Laboratory Practices (GLP), and regulatory requirements governing pharmaceutical manufacturing, distribution, and dispensing.",
            "Community and Hospital Pharmacy Practices: To provide practical training and exposure to community pharmacy operations, hospital pharmacy management, drug inventory control, and pharmaceutical care services.",
            "Professional Development: To foster professionalism, communication skills, teamwork abilities, and ethical behavior among students, preparing them for effective interaction with healthcare professionals, patients, and the community.",
            "Emerging Trends and Technologies: To introduce students to emerging trends in pharmacy practice, such as digital health technologies, telemedicine, and pharmaceutical informatics, enhancing their ability to adapt to technological advancements in the field.",
            "Preparation for Further Studies: To prepare students for advanced studies in pharmacy, such as pursuing higher diplomas, bachelor’s degrees (B.Pharm), or other specialized certifications in areas like clinical pharmacy, industrial pharmacy, or regulatory affairs.",
        ],
    },
    {
        id: 5,
        title: "PGDM – Finance",
        courseDuration: "2 Years",
        eligibility:
            "Graduation with (minimum of 40%-45% aggregate marks) or any equivalent degree from a recognized institution",
        img: "/photos/snbImages/c5.png",
        description:
            "A Post Graduate Diploma in Management (PGDM) with a specialization in Finance equips graduates with a strong foundation in financial management, investment analysis, and financial markets. The course is designed to provide students with comprehensive knowledge and skills in various aspects of finance, including corporate finance, investment management, risk management, and financial technology.",
        careerOpportunities: [
            "Investment Banker",
            "Corporate Finance",
            "Financial Analysis and Research",
            "Asset Management",
            "Risk Management",
            "Financial Consulting",
            "Financial Planning",
            "Insurance and Actuarial Science",
            "Financial Technology (FinTech)",
            "Academic and Research Institutions",
        ],
        courseObjectives: [
            "Fundamental Knowledge of Finance: Provide students with a solid foundation in finance theories, principles, and concepts, including financial markets, financial instruments, financial statements analysis, and valuation techniques.",
            "Specialized Skills in Financial Management: Develop expertise in financial management practices such as capital budgeting, working capital management, cost of capital, dividend policy, and financial risk management.",
            "Investment Analysis and Portfolio Management: Equip students with the skills to analyze investment opportunities, construct and manage investment portfolios, and make informed investment decisions.",
            "Corporate Finance: Provide insights into corporate finance strategies, including financing decisions, capital structure, mergers and acquisitions, corporate governance, and financial restructuring.",
            "Financial Modeling and Analysis: Teach students to use financial models and quantitative techniques for financial forecasting, budgeting, scenario analysis, and decision-making.",
            "Risk Management: Understand and manage financial risks, including market risk, credit risk, liquidity risk, and operational risk.",
            "Ethical and Regulatory Framework: Familiarize students with ethical considerations in finance and regulatory frameworks governing financial markets and institutions.",
            "Application of Financial Technology: Introduce students to the impact of financial technology (FinTech) on finance practices, including digital payments, blockchain technology, algorithmic trading, and data analytics.",
            "Critical Thinking and Problem Solving: Develop critical thinking skills to evaluate complex financial problems, analyze data, and formulate effective solutions.",
            "Industry Exposure and Practical Experience: Provide opportunities for internships, industry projects, and guest lectures by industry experts to gain practical insights and enhance employability in finance-related careers.",
        ],
    },
    {
        id: 6,
        title: "PGDM – Hospital & Health Management",
        courseDuration: "2 Years",
        eligibility:
            "Graduation with (minimum of 40%-45% aggregate marks) or any equivalent degree from a recognized institution",
        img: "/photos/snbImages/c2.png",
        description:
            "A Post Graduate Diploma in Management (PGDM) with a specialization in Hospital and Health Management prepares graduates to take on leadership roles in the healthcare industry. The program covers a wide range of topics, including healthcare administration, healthcare systems, financial management, human resources, and healthcare technology, equipping students with the skills necessary to manage hospitals, health services, and other healthcare organizations effectively.",
        careerOpportunities: [
            "Healthcare Administrator",
            "Hospital Manager",
            "Health Services Manager",
            "Medical and Health Services Manager",
            "Clinical Operations Manager",
            "Health Policy Analyst",
            "Quality Assurance Manager",
            "Health Information Manager",
            "Healthcare Consultant",
            "Patient Services Manager",
            "Health Project Manager",
            "Public Health Administrator",
            "Pharmaceutical and Biotech Management",
            "Health Insurance Manager",
        ],
        courseObjectives: [
            "Leadership and Management Skills: Develop strong leadership and management skills to effectively lead healthcare teams and organizations. This includes strategic planning, decision-making, and organizational behavior.",
            "Healthcare Systems Understanding: Gain a comprehensive understanding of healthcare systems, including public and private sectors, healthcare policies, regulations, and the socio-economic factors affecting health services.",
            "Financial Management: Acquire skills in financial management specific to healthcare settings, including budgeting, financial analysis, and resource allocation.",
            "Operational Efficiency: Learn to improve operational efficiency and quality of care within healthcare organizations by applying principles of operations management and process optimization.",
            "Human Resource Management: Develop expertise in managing human resources in healthcare settings, including recruitment, training, performance management, and addressing staff issues.",
            "Healthcare Technology: Understand the role of technology in healthcare, including electronic health records (EHRs), health information systems, and telemedicine, and how to manage technological advancements.",
            "Strategic Planning: Learn to create and implement strategic plans that align with the goals of healthcare organizations and address current and future challenges in the healthcare industry.",
            "Legal and Ethical Issues: Gain insight into legal and ethical issues in healthcare management, including compliance with laws, regulations, and ethical standards.",
            "Patient Care and Quality Assurance: Develop strategies to enhance patient care and ensure quality assurance, including patient safety, satisfaction, and continuous improvement processes.",
            "Health Policy and Advocacy: Understand the impact of health policies on healthcare delivery and learn how to advocate for policies that improve health outcomes and system efficiency.",
            "Research and Data Analysis: Learn to conduct research and analyze data to make informed decisions and improve healthcare practices.",
            "Communication Skills: Enhance communication skills for effective interaction with various stakeholders, including patients, staff, and external partners.",
        ],
    },
    {
        id: 7,
        title: "PGDM – Business Management",
        courseDuration: "2 Years",
        eligibility:
            "Graduation with (minimum of 40%-45% aggregate marks) or any equivalent degree from a recognized institution",
        img: "/photos/snbImages/c6.png",
        description:
            "A Post Graduate Diploma in Management (PGDM) with a specialization in Business Management provides students with a comprehensive understanding of various aspects of business operations and management. The program covers key areas such as strategic management, marketing, finance, operations, human resources, and entrepreneurship, preparing graduates to take on leadership roles in diverse business environments.",
        careerOpportunities: [
            "Management Consulting",
            "Corporate Management",
            "Entrepreneurship and Startups",
            "Marketing and Brand Management",
            "Financial Services",
            "Operations and Supply Chain Management",
            "Human Resources Management",
            "International Business",
            "Retail Management",
            "E-commerce and Digital Business",
            "Nonprofit and Government Organizations",
            "Education and Academia",
        ],
        courseObjectives: [
            "Foundational Knowledge: Equip students with a solid foundation in business management theories, concepts, and principles across disciplines such as marketing, finance, operations, human resources, and strategy.",
            "Strategic Management: Develop skills in strategic thinking and analysis, enabling students to formulate business strategies that align with organizational goals and respond effectively to competitive dynamics and market changes.",
            "Leadership and Decision-Making: Cultivate leadership qualities and decision-making skills necessary for effective management and execution of business strategies. This includes understanding leadership styles, team dynamics, and conflict resolution.",
            "Entrepreneurship and Innovation: Foster an entrepreneurial mindset by encouraging creativity, innovation, and risk-taking. Students learn to identify business opportunities, develop business plans, and launch new ventures.",
            "Marketing Management: Provide insights into marketing strategies, consumer behavior, brand management, market research, digital marketing, and sales management. Students learn to create value for customers and build strong market positions.",
            "Financial Management: Develop proficiency in financial analysis, budgeting, cost management, capital budgeting, and financial decision-making. Students understand how financial strategies impact business performance and sustainability.",
            "Operations and Supply Chain Management: Understand the principles of operations management, supply chain dynamics, logistics management, quality control, and process optimization. Students learn to enhance efficiency and productivity in business operations.",
            "Human Resource Management: Explore HR strategies, talent acquisition and management, performance appraisal, compensation and benefits, employee relations, and organizational development. Students learn to foster a positive work environment and manage human capital effectively.",
            "Business Ethics and Corporate Social Responsibility (CSR): Promote ethical behavior and responsible business practices. Students understand the importance of CSR initiatives, sustainability, and stakeholder management in business operations.",
            "Global Business Perspective: Provide exposure to global markets, international business strategies, cross-cultural management, and global supply chain dynamics. Students learn to navigate complexities in global business environments.",
        ],
    },
    {
        id: 8,
        title: "PGDM – Human Resource Management",
        courseDuration: "2 Years",
        eligibility:
            "Graduation with (minimum of 40%-45% aggregate marks) or any equivalent degree from a recognized institution",
        img: "/photos/snbImages/c7.png",
        description:
            "A Post Graduate Diploma in Management (PGDM) with a specialization in Human Resource Management prepares graduates to take on strategic roles in managing an organization's human capital. The program covers a wide range of HR topics, including talent acquisition, employee relations, compensation management, HR analytics, and organizational development, equipping students with the skills necessary to lead HR functions effectively.",
        careerOpportunities: [
            "Human Resource Manager/Generalist",
            "Talent Acquisition Specialist",
            "HR Business Partner",
            "Compensation and Benefits Manager",
            "Training and Development Manager",
            "Employee Relations Manager",
            "Organizational Development Consultant",
            "HR Consultant",
            "Recruitment Consultant",
            "Labor Relations Specialist",
            "HR Analytics Specialist",
            "Chief Human Resources Officer (CHRO)",
        ],
        courseObjectives: [
            "Develop Advanced HR Expertise: Deepen your knowledge in specialized HR areas such as talent management, organizational development, compensation and benefits, and employee relations.",
            "Achieve Professional Certification: Pursue certifications like the Society for Human Resource Management (SHRM) or the Human Resource Certification Institute (HRCI) to enhance your credentials and demonstrate your expertise.",
            "Gain Strategic HR Leadership Skills: Aim to take on leadership roles that require strategic thinking and decision-making, such as HR Manager, HR Business Partner, or even Chief Human Resources Officer (CHRO).",
            "Enhance HR Analytics Capabilities: Develop skills in HR analytics to leverage data for strategic decision-making, improve workforce planning, and measure the impact of HR initiatives.",
            "Expand Industry Knowledge: Gain insights into specific industries or sectors that interest you, understanding their unique HR challenges and best practices.",
            "Improve Change Management Skills: Learn how to manage organizational change effectively, including leading restructuring efforts, mergers, or cultural transformations.",
            "Build a Professional Network: Connect with other HR professionals, attend industry conferences, and join HR associations to stay updated on trends and opportunities in the field.",
            "Strengthen Communication and Negotiation Skills: Hone your abilities to effectively communicate with employees, management, and external stakeholders, and negotiate effectively in various HR contexts.",
            "Pursue Continuous Learning: Engage in lifelong learning through workshops, seminars, online courses, or advanced degrees to stay current with evolving HR practices and technologies.",
            "Contribute to Organizational Culture: Focus on initiatives that enhance workplace culture, employee engagement, and organizational effectiveness, fostering a positive and productive work environment.",
            "Advocate for Diversity, Equity, and Inclusion (DEI): Develop and implement DEI strategies to create a more inclusive workplace and address any disparities within the organization.",
            "Drive Employee Well-being Initiatives: Promote and manage programs that support employee health, wellness, and work-life balance, improving overall job satisfaction and productivity.",
        ],
    },
    {
        id: 9,
        title: "PGDM – Finance Marketing & HRM (Integrated)",
        courseDuration: "2 Years",
        eligibility:
            "Graduation with (minimum of 40%-45% aggregate marks) or any equivalent degree from a recognized institution",
        img: "/photos/snbImages/c1.png",
        description:
            "A Post Graduate Diploma in Management (PGDM) with a specialization in Finance, Marketing, and Human Resource Management (HRM) provides a comprehensive understanding of business operations across these three critical areas. The program is designed to equip students with the skills necessary to manage and integrate finance, marketing, and HR functions within an organization, preparing them for leadership roles in a wide range of industries.",
        careerOpportunities: [
            "Business Development Manager: Drive growth strategies by identifying new business opportunities and developing relationships with clients.",
            "General Manager: Oversee all aspects of a business unit or department, integrating finance, marketing, and HR functions.",
            "Strategic Planner: Develop long-term strategies that integrate finance, marketing, and HR insights.",
            "Financial Analyst/Manager: Analyze financial data and provide recommendations that align with marketing and HR strategies.",
            "Marketing Manager: Develop and implement marketing strategies while coordinating with HR for talent needs.",
            "HR Manager/Business Partner: Manage HR functions with an understanding of marketing and finance to align HR strategies with business goals.",
            "Product Manager: Oversee product development and marketing, using financial and HR insights to guide decisions.",
            "Financial Marketing Analyst: Analyze financial markets and trends to inform marketing strategies.",
            "Business Consultant: Provide expert advice on improving finance, marketing, and HR functions.",
            "Corporate Strategy Analyst: Develop corporate strategies that integrate insights from finance, marketing, and HR.",
            "Entrepreneur/Startup Founder: Manage your own business, utilizing integrated knowledge to handle finance, marketing, and HR effectively.",
            "Investment Banker or Financial Consultant: Provide financial advice and manage investments with a deep understanding of market trends.",
            "Operations Manager: Oversee day-to-day operations by integrating finance, marketing, and HR practices.",
        ],
        courseObjectives: [
            "Holistic Business Understanding: Develop a deep understanding of how finance, marketing, and HRM functions interact to impact overall business performance.",
            "Strategic Decision-Making: Equip students with the ability to make strategic decisions that align financial goals with marketing initiatives and HR policies.",
            "Financial Management and Analysis: Provide a solid foundation in financial principles, including budgeting and investment management.",
            "Marketing Strategy Implementation: Teach advanced marketing strategies and digital marketing techniques to drive business growth.",
            "HR Management Skills: Develop expertise in HR functions such as recruitment, employee relations, and performance management.",
            "Leadership and Management: Cultivate leadership skills essential for overseeing teams and projects across finance, marketing, and HR.",
            "Ethical Practices and Governance: Emphasize the importance of ethical behavior and governance in business operations.",
            "Analytical Problem-Solving: Develop strong analytical skills to assess complex business problems and devise effective solutions.",
            "Innovation and Adaptability: Encourage innovative thinking and adaptability to evolving business environments.",
            "Effective Communication: Improve communication skills necessary for collaboration and negotiation across departments.",
            "Global Business Awareness: Prepare students to operate effectively in a global business environment.",
            "Entrepreneurship and Consultancy: Equip students with skills to start and manage their own business ventures or provide consultancy services.",
        ],
    },
];